<template>
  <div class="main">
    <OrderManagementStates />
  </div>
</template>

<script>
import OrderManagementStates from '@/components/dashboard/orderManagementDashboard/OrderManagementStates.vue'

export default {
  components: {
    OrderManagementStates,
  },
}
</script>

<style>

</style>
