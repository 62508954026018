<template>
  <div
    class="stateChart"
  >
    <div
      class="filtering"
      style="padding-bottom: 10px;display:flex;align-items:center;gap:5px;"
    >
      <p style="margin-bottom:0px;">
        {{ $t("Year") }} :
      </p>
      <vue-select
        v-model="year"
        style="width:170px;"
        :clearable="false"
        :options="yearsArray"
        :placeholder="$t('Select')"
      />
      <p style="margin-bottom:0px;">
        {{ $t("Month") }} :
      </p>
      <vue-select
        id="input-5"
        v-model="month"
        :reduce="(e) => e.value"
        required
        :options="months"
        label="name"
        style="width: 170px"
        :placeholder="$t('Month')"
        aria-describedby="input-1-live-feedback"
      />
    </div>
    <div
      class="light-card__wrapper"
    >
      <div
        v-for="(item, key) in getOrdersByPickupDates"
        :key="key"
        v-b-tooltip.hover
        class="light-card"
        :title="getLanguage === 'en' ? `${$t('ClickFor')} ${$t(item.orderStateDisplayName)} ${$t('Details')}` : `${$t('ClickForDetails')} ${$t(item.orderStateDisplayName)}`"
        @click="resetOrdersOverview(),sentYear"
      >

        <div
          :disabled="getLoggedInUser.role == 'Sewer' && item.orderItemState == 'Created'"
          :event="getLoggedInUser.role == 'Sewer' && item.orderItemState == 'Created' ? '' : 'click'"
          style="text-decoration: none; display: flex; flex-direction: column; align-items: center; justify-content: space-between; width: 100%;"
          @click="sentYear(item.orderItemState)"
        >
          <img
            :src="require(`@/assets/images/ValdrinSahiti/Dashboard/LogoDashboard-${getLoggedInUser.role == 'TeamLeader'? key+=1 : getLoggedInUser.role == 'Deliverer'? key+=7 : key+1}.svg`)"
            alt=""
          >
          <div class="card-content">
            <h6>
              {{ $t(`${item.orderStateDisplayName}`) }}
            </h6>
            <h4>
              <ICountUp
                :end-val="item.count"
                :options="options1"
              />
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ICountUp from 'vue-countup-v2';
import moment from 'moment'

export default {
  components: {
    ICountUp,
  },
  props: [],
  data() {
    return {
      year: moment().format('YYYY'),
      yearsArray: [],
      startDate: null,
      endDate: null,
      month: moment().format('MM'),
      months: [
        {
          value: 1,
          name: this.$t('January'),
        },
        {
          value: 2,
          name: this.$t('February'),
        },
        {
          value: 3,
          name: this.$t('March'),
        },
        {
          value: 4,
          name: this.$t('April'),
        },
        {
          value: 5,
          name: this.$t('May'),
        },
        {
          value: 6,
          name: this.$t('June'),
        },
        {
          value: 7,
          name: this.$t('July'),
        },
        {
          value: 8,
          name: this.$t('August'),
        },
        {
          value: 9,
          name: this.$t('September'),
        },
        {
          value: 10,
          name: this.$t('October'),
        },
        {
          value: 11,
          name: this.$t('November'),
        },
        {
          value: 12,
          name: this.$t('December'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getOrdersByPickupDates', 'getLoggedInUser']),
  },
  watch: {
    year(value) {
      this.loadOrdersGrouppedByPickUpDate({
        year: value,
        month: this.month,
      })
    },
    month(value) {
      this.loadOrdersGrouppedByPickUpDate({
        year: this.year,
        month: value,
      })
    },
  },
  mounted() {
    this.fetch()
    const currentYear = new Date().getFullYear();
    for (let i = 0; i <= 5; i++) {
      this.yearsArray.push(currentYear - i);
    }
  },
  methods: {
    ...mapActions(['loadOrdersGrouppedByPickUpDate']),
    async fetch() {
      try {
        await this.loadOrdersGrouppedByPickUpDate({
          year: this.year,
          month: this.month,
        })
          .then((res) => {
            console.log(res);
          })
      } catch (e) {
        console.log(e);
      }
    },
    sentYear(value) {
      this.$emit('yearChanged', { year: this.year, orderState: value });
      this.$router.push({
        path: `orders-overview/${value}`,
        query: { year: this.year },
      });
    },
  },
};
</script>

  <style lang="scss" scoped>

  .main{
    // padding: 70px 15px 0px 105px;
    display: flex;
    flex-direction: column;
  }

  .card-content h6{
    font-size: 15px;
  }

  .stateChart{
    width: 100%;
    display: flex;
  }

  .line{
    width: 50%;
  }

  .tableicons{
    text-align: center;
  }

  .tableicons img{
    width: 90px;
  }

  .tableLeads{
    display: flex;
    width: 100%;
  }

  .chart{
    width: 99%;
  }

  .nav-tabs .nav-item .nav-link {
      background-color: transparent !important; /* Remove default background */
    }

    .nav-tabs .nav-item.active .nav-link {
      background-color: red !important; /* Add your custom background color */
      color: #ffffff !important; /* Text color for the active tab */
    }

  .row{
    // margin-top:100px;
  }

  .custom-carousel .carousel-indicators {
    display: none !important;
  }
  .slider{
    width: 100%;
  }

  .slick-slide slick-active slick-current{
    width: 100%
  }

  .slick-initialized .slick-slide[data-v-e4caeaf8]{
    width: 100%
  }
  .slick-track{
    width: 100%
  }

  .mainPagination{
    font-size: 12px;
  }


  .slick-initialized .slick-slide[data-v-e4caeaf8]{
    width: 100%
  }

  .b-tabs .nav-item .nav-link {
    background-color: rgb(80, 80, 86) !important;
    color: white;
  }
  .b-tabs .nav-link.active {
    background-color: rgb(99, 99, 113) !important;
  }

  .light-card__wrapper {
    padding: 0 0px 15px 0px;
    grid-template-columns: repeat(9, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: white !important;
    padding: 0px;
    border-radius: 7px;
    border: 1px solid #DDE0E5;
  }
  .card-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .upcomingLowest{
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-evenly;
  }

  .barPieChart{
    display: flex;
  }

  .main__title{
    margin-bottom: 30px;
    font-size: 30px;
    margin-top: 50px;
    text-align: center;
  }
   .hide {
    display: none;
   }

  .buttonTime{
    background: transparent linear-gradient(52deg, #262E6C 0%, #9B2A5C 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
      color: #f4f1ed;
      border-color: #262E6C;
  }

  .buttons{
    width: 50%
  }
   .light-card {
          overflow-wrap: anywhere;
          border-right:1px solid #DDE0E5;
          border-bottom:1px solid #DDE0E5;
          min-height: 160px;
    }

    .team_table{
      box-shadow: none;
      background-color: white;
    }

  @media screen and (min-width: 1400){
    .chart{
      width: 100% !important;
    }
  }

  @media screen and (min-width: 1341px) and (max-width: 1650px){
    .card-content h6{
      font-size: 13px;
    }
    .tableicons img{
      width: 70px !important;
    }
  }

   @media screen and (max-width: 1340px) {
    .main {
      padding: 80px 10px 0 90px !important;
      .container {
        margin-right: 0;
        margin-left: 0;
        max-width: inherit;
      }
      .team_table{
        width: 90%;
      }

      .upcomingLowest{
        display: flex;
        flex-direction: column;
        margin-left: 50px;
      }
      .mainPagination{
        font-size: 12px;
        width: 90%;
      }

      .tableicons img{
        width: 80px;
      }
    }
    .stateChart{
      width: 100%;
      display: block;
    }
    .line{
      width: 90%;
      margin-top: 30px;
      margin-left: 50px;
    }
    .tableLeads{
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .light-card__wrapper {
      padding: 0 0px 15px 0px;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      background-color: white !important;
      padding: 0px;
      border-radius: 7px;
      border: 1px solid #DDE0E5;
    }
  }

  @media screen and (max-width: 1300px){
    .main{
      padding: 140px 39px 0px 40px !important;
    }

    .light-card__wrapper {
    padding: 0 0px 15px 0px;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: white !important;
    padding: 0px;
    border-radius: 7px;
    border: 1px solid #DDE0E5;
  }
  }

  @media screen and (max-width: 1100px){
    .main{
      padding: 140px 39px 0px 40px !important;
    }

    .light-card__wrapper {
      padding: 0 0px 15px 0px;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }
  }

   @media screen and (max-width: 800px) {
    .main {
      padding: 130px 25px 0px 25px !important;
      .container {
        .light-card__wrapper {
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 25px;
          margin-left: 8px;
          margin-right: 8px;
        }

        .bar-pie-chart{
          display: block;
        }

        .light-card {
          overflow-wrap: anywhere;
        }
      }
      .upcomingLowest{
        display: flex;
        flex-direction: column;
        margin-left: 50px;
      }
      .main__title{
        margin-bottom: 10px;
        font-size: 30px;
        margin-top: 50px;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1340px) {
    .main {
      padding: 180px 0 0 90px !important;
      .container {
        margin-right: 0;
        margin-left: 0;
        max-width: inherit;
      }
      .team_table {
        width: 90%;
      }

      .upcomingLowest {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
      }
      .mainPagination {
        font-size: 12px;
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 1190px){
    .main{
      padding: 130px 40px 0 40px !important;
    }
  }
  .active-button {
    background: transparent linear-gradient(52deg, #f7f7f7 0%, #fbfbfb 100%, #FF274F 100%) 0% 0% no-repeat padding-box;
      color: #262E6C !important;
      border-color: black;
  }

  </style>
